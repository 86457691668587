import React from "react";

export const MaintenanceDepartmentIcon = (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.75 8.24805V12.4147" stroke="white" strokeOpacity="0.4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.75 18.5895H5.70002C2.80835 18.5895 1.60002 16.5228 3.00002 13.9978L5.60002 9.31445L8.05002 4.91445C9.53335 2.23945 11.9667 2.23945 13.45 4.91445L15.9 9.32279L18.5 14.0061C19.9 16.5311 18.6833 18.5978 15.8 18.5978H10.75V18.5895Z" stroke="white" strokeOpacity="0.4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.7454 14.915H10.7529" stroke="white" strokeOpacity="0.4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export function Overview() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1528 5.55553C10.2037 5.65919 10.2373 5.77021 10.2524 5.88434L10.5308 10.0243L10.669 12.1051C10.6705 12.3191 10.704 12.5317 10.7687 12.736C10.9356 13.1326 11.3372 13.3846 11.7741 13.367L18.4313 12.9315C18.7196 12.9268 18.998 13.0346 19.2052 13.2313C19.3779 13.3952 19.4894 13.6096 19.5246 13.8402L19.5364 13.9802C19.2609 17.7949 16.4592 20.9767 12.6524 21.798C8.84555 22.6193 4.94186 20.8843 3.06071 17.5349C2.51839 16.5618 2.17965 15.4923 2.06438 14.389C2.01623 14.0624 1.99503 13.7325 2.00098 13.4025C1.99503 9.31273 4.90747 5.77696 8.98433 4.92457C9.47501 4.84816 9.95603 5.10792 10.1528 5.55553Z"
        fill="#1A355E"
      />
      <path
        opacity="0.4"
        d="M12.87 2.00082C17.4299 2.11683 21.2623 5.39579 22 9.81229L21.993 9.84488L21.9728 9.89227L21.9756 10.0224C21.9652 10.1947 21.8986 10.3605 21.784 10.4945C21.6645 10.634 21.5013 10.729 21.3216 10.7659L21.212 10.7809L13.5312 11.2786C13.2757 11.3038 13.0213 11.2214 12.8314 11.052C12.673 10.9107 12.5718 10.7201 12.5432 10.5147L12.0277 2.84506C12.0187 2.81913 12.0187 2.79102 12.0277 2.76508C12.0347 2.55367 12.1278 2.35384 12.286 2.21023C12.4443 2.06662 12.6547 1.9912 12.87 2.00082Z"
        fill="#1A355E"
      />
    </svg>
  );
}

export function Setting() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2836 16.1827C12.9787 16.1827 11.9231 15.1685 11.9231 13.8947C11.9231 12.6209 12.9787 11.5986 14.2836 11.5986C15.5885 11.5986 16.6191 12.6209 16.6191 13.8947C16.6191 15.1685 15.5885 16.1827 14.2836 16.1827Z"
        fill="#959DB2"
      />
      <path
        opacity="0.4"
        d="M21.7805 15.8095C21.6226 15.566 21.3982 15.3226 21.1073 15.1685C20.8746 15.0549 20.725 14.8683 20.592 14.6492C20.1681 13.9515 20.4175 13.0347 21.1239 12.6209C21.9551 12.1584 22.221 11.128 21.739 10.3248L21.1821 9.36738C20.7084 8.56415 19.6694 8.28018 18.8466 8.75076C18.1152 9.14021 17.176 8.88058 16.7521 8.19094C16.6191 7.96376 16.5443 7.72036 16.5609 7.47695C16.5859 7.16053 16.4861 6.86033 16.3365 6.61693C16.029 6.1139 15.4721 5.77313 14.8571 5.77313H13.6852C13.0784 5.78936 12.5216 6.1139 12.214 6.61693C12.0561 6.86033 11.9647 7.16053 11.9813 7.47695C11.998 7.72036 11.9231 7.96376 11.7902 8.19094C11.3663 8.88058 10.4271 9.14021 9.70399 8.75076C8.87284 8.28018 7.84222 8.56415 7.36016 9.36738L6.80329 10.3248C6.32954 11.128 6.59551 12.1584 7.41834 12.6209C8.12481 13.0347 8.37416 13.9515 7.95858 14.6492C7.81729 14.8683 7.66768 15.0549 7.43496 15.1685C7.15237 15.3226 6.90303 15.566 6.77005 15.8095C6.46252 16.3125 6.47915 16.9453 6.78667 17.4727L7.36016 18.4463C7.66768 18.9656 8.24117 19.2901 8.8396 19.2901C9.12219 19.2901 9.45465 19.209 9.72061 19.0467C9.9284 18.9088 10.1777 18.8601 10.452 18.8601C11.2749 18.8601 11.9647 19.5335 11.9813 20.3367C11.9813 21.2698 12.746 22 13.7101 22H14.8405C15.7963 22 16.5609 21.2698 16.5609 20.3367C16.5859 19.5335 17.2757 18.8601 18.0986 18.8601C18.3645 18.8601 18.6139 18.9088 18.83 19.0467C19.0959 19.209 19.4201 19.2901 19.711 19.2901C20.3011 19.2901 20.8746 18.9656 21.1821 18.4463L21.7639 17.4727C22.0631 16.9291 22.0881 16.3125 21.7805 15.8095Z"
        fill="#959DB2"
      />
      <path
        d="M8.12755 6.03716C8.06403 5.93925 7.97376 5.84134 7.85674 5.77933C7.76313 5.73364 7.70295 5.65858 7.64946 5.57046C7.47895 5.28978 7.57925 4.92099 7.86343 4.75454C8.19776 4.56851 8.30475 4.15402 8.11084 3.83092L7.88683 3.44581C7.69626 3.1227 7.27835 3.00848 6.94736 3.19777C6.65315 3.35442 6.27535 3.24999 6.10484 2.97257C6.05135 2.88119 6.02126 2.78328 6.02794 2.68537C6.03797 2.55809 5.99785 2.43733 5.93767 2.33942C5.81397 2.13707 5.58997 2 5.34256 2H4.87115C4.62709 2.00653 4.40309 2.13707 4.27938 2.33942C4.21586 2.43733 4.17908 2.55809 4.18577 2.68537C4.19246 2.78328 4.16237 2.88119 4.10887 2.97257C3.93836 3.24999 3.56057 3.35442 3.2697 3.19777C2.93536 3.00848 2.52079 3.1227 2.32688 3.44581L2.10288 3.83092C1.91231 4.15402 2.01929 4.56851 2.35028 4.75454C2.63446 4.92099 2.73476 5.28978 2.5676 5.57046C2.51076 5.65858 2.45058 5.73364 2.35697 5.77933C2.24329 5.84134 2.143 5.93925 2.0895 6.03716C1.9658 6.23951 1.97249 6.49408 2.09619 6.70622L2.32688 7.09786C2.45058 7.30673 2.68127 7.43728 2.92199 7.43728C3.03566 7.43728 3.1694 7.40464 3.27638 7.33937C3.35997 7.28389 3.46027 7.26431 3.5706 7.26431C3.90159 7.26431 4.17908 7.53519 4.18577 7.8583C4.18577 8.23362 4.49336 8.52735 4.88118 8.52735H5.33587C5.72036 8.52735 6.02794 8.23362 6.02794 7.8583C6.03797 7.53519 6.31547 7.26431 6.64646 7.26431C6.75345 7.26431 6.85375 7.28389 6.94067 7.33937C7.04766 7.40464 7.17805 7.43728 7.29507 7.43728C7.53244 7.43728 7.76313 7.30673 7.88683 7.09786L8.12087 6.70622C8.24123 6.48755 8.25126 6.23951 8.12755 6.03716Z"
        fill="#959DB2"
      />
      <path
        d="M5.11189 6.1873C4.58699 6.1873 4.16238 5.77934 4.16238 5.26694C4.16238 4.75455 4.58699 4.34332 5.11189 4.34332C5.63679 4.34332 6.05137 4.75455 6.05137 5.26694C6.05137 5.77934 5.63679 6.1873 5.11189 6.1873Z"
        fill="#D5D8E0"
      />
    </svg>
  );
}

export function Clock() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.34 1.99982H7.67C4.28 1.99982 2 4.37982 2 7.91982V16.0898C2 19.6198 4.28 21.9998 7.67 21.9998H16.34C19.73 21.9998 22 19.6198 22 16.0898V7.91982C22 4.37982 19.73 1.99982 16.34 1.99982Z"
        fill="#959DB2"
      />
      <path
        d="M15.5734 15.8143C15.4424 15.8143 15.3104 15.7803 15.1894 15.7093L11.2634 13.3673C11.0374 13.2313 10.8984 12.9863 10.8984 12.7223V7.67529C10.8984 7.26129 11.2344 6.92529 11.6484 6.92529C12.0624 6.92529 12.3984 7.26129 12.3984 7.67529V12.2963L15.9584 14.4193C16.3134 14.6323 16.4304 15.0923 16.2184 15.4483C16.0774 15.6833 15.8284 15.8143 15.5734 15.8143Z"
        fill="#959DB2"
      />
    </svg>
  );
}

export function GeoMap() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M19.88 20.9401C18.93 21.6401 17.68 22.0001 16.19 22.0001H7.81C7.57 22.0001 7.33001 21.9901 7.10001 21.9601L14 15.0601L19.88 20.9401Z"
        fill="#959DB2"
      />
      <path
        opacity="0.4"
        d="M22 7.80997V16.19C22 17.68 21.64 18.93 20.94 19.88L15.06 14L21.96 7.09998C21.99 7.32998 22 7.56997 22 7.80997Z"
        fill="#959DB2"
      />
      <path
        opacity="0.4"
        d="M15.06 14L20.94 19.88C20.65 20.3 20.3 20.65 19.88 20.94L14 15.06L7.10001 21.96C6.46001 21.92 5.88001 21.79 5.35001 21.59C3.21001 20.81 2 18.91 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C18.91 2 20.81 3.21 21.59 5.35C21.79 5.88 21.92 6.46 21.96 7.1L15.06 14Z"
        fill="#959DB2"
      />
      <path
        d="M15.06 14L20.94 19.88C20.65 20.3 20.3 20.65 19.88 20.94L14 15.06L7.10001 21.96C6.46001 21.92 5.88001 21.79 5.35001 21.59L5.73999 21.2L21.59 5.34998C21.79 5.87998 21.92 6.45998 21.96 7.09998L15.06 14Z"
        fill="#959DB2"
      />
      <path
        d="M12.24 7.93003C11.86 6.28003 10.4 5.54003 9.11999 5.53003C7.83999 5.53003 6.38 6.27003 6 7.92003C5.58 9.75003 6.69999 11.28 7.70999 12.24C8.10999 12.62 8.60999 12.8 9.11999 12.8C9.62999 12.8 10.13 12.61 10.53 12.24C11.54 11.28 12.66 9.75003 12.24 7.93003ZM9.14999 9.49003C8.59999 9.49003 8.14999 9.04003 8.14999 8.49003C8.14999 7.94003 8.58999 7.49003 9.14999 7.49003H9.16C9.71 7.49003 10.16 7.94003 10.16 8.49003C10.16 9.04003 9.69999 9.49003 9.14999 9.49003Z"
        fill="#959DB2"
      />
    </svg>
  );
}

export function AssetsBox() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M26.3008 8.9875L15.0008 15.525L3.70078 8.9875C4.20078 8.0625 4.92578 7.25 5.73828 6.8L12.4133 3.1C13.8383 2.3 16.1633 2.3 17.5883 3.1L24.2633 6.8C25.0758 7.25 25.8008 8.0625 26.3008 8.9875Z"
        fill="#1A355E"
      />
      <path
        opacity="0.6"
        d="M15.0008 15.525V27.5C14.0633 27.5 13.1258 27.3 12.4133 26.9L5.73828 23.2C4.22578 22.3625 2.98828 20.2625 2.98828 18.5375V11.4625C2.98828 10.6625 3.26328 9.78749 3.70078 8.98749L15.0008 15.525Z"
        fill="#1A355E"
      />
      <path
        d="M27.0133 11.4625V18.5375C27.0133 20.2625 25.7758 22.3625 24.2633 23.2L17.5883 26.9C16.8758 27.3 15.9383 27.5 15.0008 27.5V15.525L26.3008 8.98749C26.7383 9.78749 27.0133 10.6625 27.0133 11.4625Z"
        fill="#1A355E"
      />
      <path
        d="M22.8095 14.5353L21.1857 15.4728L21.1857 17.6563L19.2913 18.75L19.2913 20.625L21.1857 19.5313L21.1857 21.7147L22.8095 20.7772L22.8095 18.5938L24.7039 17.5L24.7039 15.625L22.8095 16.7188L22.8095 14.5353Z"
        fill="white"
      />
      <path
        d="M7.43572 14.5353L9.05952 15.4728L9.05952 17.6563L10.9539 18.75L10.9539 20.625L9.05952 19.5313L9.05952 21.7147L7.43572 20.7772L7.43572 18.5938L5.54129 17.5L5.54129 15.625L7.43572 16.7188L7.43572 14.5353Z"
        fill="white"
      />
    </svg>
  );
}

export function ClassA() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#8284E0" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M17.2737 9.19419L12.0004 12.245L6.72703 9.19419C6.96037 8.76252 7.2987 8.38335 7.67787 8.17335L10.7929 6.44669C11.4579 6.07335 12.5429 6.07335 13.2079 6.44669L16.3229 8.17335C16.702 8.38335 17.0404 8.76252 17.2737 9.19419Z"
        fill="#061327"
      />
      <path
        opacity="0.6"
        d="M12.0004 12.245V17.8333C11.5629 17.8333 11.1254 17.74 10.7929 17.5533L7.67786 15.8267C6.97203 15.4358 6.39453 14.4558 6.39453 13.6508V10.3492C6.39453 9.97582 6.52286 9.56749 6.72703 9.19415L12.0004 12.245Z"
        fill="#061327"
      />
      <path
        d="M17.6062 10.3492V13.6508C17.6062 14.4558 17.0287 15.4358 16.3229 15.8267L13.2079 17.5533C12.8754 17.74 12.4379 17.8333 12.0004 17.8333V12.245L17.2737 9.19415C17.4779 9.56749 17.6062 9.97582 17.6062 10.3492Z"
        fill="#061327"
      />
    </svg>
  );
}

export function ClassB() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#65AA8E" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M17.2737 9.19419L12.0004 12.245L6.72703 9.19419C6.96037 8.76252 7.2987 8.38335 7.67787 8.17335L10.7929 6.44669C11.4579 6.07335 12.5429 6.07335 13.2079 6.44669L16.3229 8.17335C16.702 8.38335 17.0404 8.76252 17.2737 9.19419Z"
        fill="#0E2041"
      />
      <path
        opacity="0.6"
        d="M12.0004 12.245V17.8333C11.5629 17.8333 11.1254 17.74 10.7929 17.5533L7.67786 15.8267C6.97203 15.4358 6.39453 14.4558 6.39453 13.6508V10.3492C6.39453 9.97582 6.52286 9.56749 6.72703 9.19415L12.0004 12.245Z"
        fill="#0E2041"
      />
      <path
        d="M17.6062 10.3492V13.6508C17.6062 14.4558 17.0287 15.4358 16.3229 15.8267L13.2079 17.5533C12.8754 17.74 12.4379 17.8333 12.0004 17.8333V12.245L17.2737 9.19415C17.4779 9.56749 17.6062 9.97582 17.6062 10.3492Z"
        fill="#0E2041"
      />
    </svg>
  );
}

export function ClassC() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#F0B78B" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M17.2737 9.19419L12.0004 12.245L6.72703 9.19419C6.96037 8.76252 7.2987 8.38335 7.67787 8.17335L10.7929 6.44669C11.4579 6.07335 12.5429 6.07335 13.2079 6.44669L16.3229 8.17335C16.702 8.38335 17.0404 8.76252 17.2737 9.19419Z"
        fill="#364E74"
      />
      <path
        opacity="0.6"
        d="M12.0004 12.245V17.8333C11.5629 17.8333 11.1254 17.74 10.7929 17.5533L7.67786 15.8267C6.97203 15.4358 6.39453 14.4558 6.39453 13.6508V10.3492C6.39453 9.97582 6.52286 9.56749 6.72703 9.19415L12.0004 12.245Z"
        fill="#364E74"
      />
      <path
        d="M17.6062 10.3492V13.6508C17.6062 14.4558 17.0287 15.4358 16.3229 15.8267L13.2079 17.5533C12.8754 17.74 12.4379 17.8333 12.0004 17.8333V12.245L17.2737 9.19415C17.4779 9.56749 17.6062 9.97582 17.6062 10.3492Z"
        fill="#364E74"
      />
    </svg>
  );
}

export function CardTotal() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="6" fill="#6C5DD3" />
      <path
        opacity="0.4"
        d="M11.0135 6.39669L8.00021 8.14002L4.98688 6.39669C5.12021 6.15002 5.31354 5.93335 5.53021 5.81335L7.31021 4.82669C7.69021 4.61335 8.31021 4.61335 8.69021 4.82669L10.4702 5.81335C10.6869 5.93335 10.8802 6.15002 11.0135 6.39669Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.6"
        d="M8.00021 8.14V11.3333C7.75021 11.3333 7.50021 11.28 7.31021 11.1733L5.53021 10.1867C5.12687 9.96333 4.79688 9.40333 4.79688 8.94333V7.05667C4.79688 6.84333 4.87021 6.61 4.98687 6.39667L8.00021 8.14Z"
        fill="#F2F2F2"
      />
      <path
        d="M11.2035 7.05667V8.94333C11.2035 9.40333 10.8735 9.96333 10.4702 10.1867L8.69021 11.1733C8.50021 11.28 8.25021 11.3333 8.00021 11.3333V8.14L11.0135 6.39667C11.1302 6.61 11.2035 6.84333 11.2035 7.05667Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export function CardWork() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="6" fill="#1A8451" />
      <path
        opacity="0.4"
        d="M9.44676 4.66663H6.55676C5.42676 4.66663 4.66676 5.45996 4.66676 6.63996V9.36329C4.66676 10.54 5.42676 11.3333 6.55676 11.3333H9.44676C10.5768 11.3333 11.3334 10.54 11.3334 9.36329V6.63996C11.3334 5.45996 10.5768 4.66663 9.44676 4.66663Z"
        fill="#F2F2F2"
      />
      <path
        d="M7.60447 9.08268C7.5298 9.08268 7.45514 9.05434 7.39814 8.99734L6.60714 8.20634C6.49314 8.09234 6.49314 7.90768 6.60714 7.79401C6.72114 7.68001 6.90547 7.67968 7.01947 7.79368L7.60447 8.37868L8.98047 7.00268C9.09447 6.88868 9.2788 6.88868 9.3928 7.00268C9.5068 7.11668 9.5068 7.30134 9.3928 7.41534L7.8108 8.99734C7.7538 9.05434 7.67914 9.08268 7.60447 9.08268Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export function CardDown() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="6" fill="#EB5757" />
      <path
        d="M10.127 7.49653L7.08336 9.37174C7.01519 9.41352 6.92792 9.40952 6.8641 9.36018C6.57493 9.1375 6.31659 8.91171 6.14423 8.72193C6.14423 8.72193 5.99574 8.56992 5.93148 8.47347C5.82771 8.34191 5.77778 8.1699 5.77778 8.00278C5.77778 7.81521 5.83248 7.63787 5.94146 7.49609C5.96621 7.47075 6.06043 7.35919 6.14944 7.26808C6.66871 6.70094 8.02423 5.764 8.73715 5.47999C8.84092 5.43421 9.11793 5.33865 9.26164 5.33331C9.40014 5.33331 9.53387 5.36354 9.66239 5.42976C9.82043 5.52088 9.94417 5.66222 10.0136 5.82978C10.0584 5.94623 10.1274 6.29558 10.1274 6.30581C10.1721 6.55515 10.2055 6.90539 10.222 7.3143C10.2251 7.38764 10.1882 7.45875 10.127 7.49653Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.4"
        d="M9.92342 8.50654C10.0576 8.42343 10.2265 8.52966 10.22 8.68922C10.2039 9.06346 10.1761 9.39325 10.1422 9.6386C10.137 9.64393 10.068 10.0791 9.98855 10.2262C9.85004 10.4995 9.57824 10.6667 9.28604 10.6667H9.26173C9.07329 10.6618 8.67254 10.4946 8.67254 10.4844C8.47065 10.4004 8.2071 10.2582 7.92358 10.0871C7.79593 10.0097 7.7929 9.81994 7.92054 9.74127L9.92342 8.50654Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export function CardRetired() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="6" fill="#B80F4B" />
      <g clipPath="url(#clip0_1653_17026)">
        <path
          opacity="0.4"
          d="M9.44663 4.66663H6.55663C5.42663 4.66663 4.66663 5.45996 4.66663 6.63996V9.36329C4.66663 10.54 5.42663 11.3333 6.55663 11.3333H9.44663C10.5766 11.3333 11.3333 10.54 11.3333 9.36329V6.63996C11.3333 5.45996 10.5766 4.66663 9.44663 4.66663Z"
          fill="white"
        />
        <path
          d="M9.00518 8.59011L8.41218 7.99744L9.00485 7.40477C9.11885 7.29111 9.11885 7.10611 9.00485 6.99244C8.89085 6.87777 8.70652 6.87811 8.59252 6.99211L7.99952 7.58477L7.40652 6.99144C7.29252 6.87744 7.10785 6.87811 6.99385 6.99144C6.88018 7.10544 6.88018 7.29044 6.99385 7.40411L7.58718 7.99744L6.99518 8.58911C6.88118 8.70311 6.88118 8.88811 6.99518 9.00144C7.05218 9.05877 7.12652 9.08711 7.20118 9.08711C7.27618 9.08711 7.35052 9.05877 7.40752 9.00177L7.99952 8.40977L8.59285 9.00277C8.64985 9.05977 8.72418 9.08811 8.79885 9.08811C8.87352 9.08811 8.94818 9.05944 9.00518 9.00277C9.11918 8.88877 9.11918 8.70411 9.00518 8.59011Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1653_17026">
          <rect width="8" height="8" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MedicalAssetsWorking() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="7" fill="#1C295D" />
      <path
        opacity="0.4"
        d="M10.6276 5.24988H7.37637C6.10512 5.24988 5.25012 6.14238 5.25012 7.46988V10.5336C5.25012 11.8574 6.10512 12.7499 7.37637 12.7499H10.6276C11.8989 12.7499 12.7501 11.8574 12.7501 10.5336V7.46988C12.7501 6.14238 11.8989 5.24988 10.6276 5.24988Z"
        fill="#F2F2F2"
      />
      <path
        d="M8.55504 10.2179C8.47104 10.2179 8.38704 10.1861 8.32292 10.1219L7.43304 9.23205C7.30479 9.1038 7.30479 8.89605 7.43304 8.76818C7.56129 8.63993 7.76867 8.63955 7.89692 8.7678L8.55504 9.42593L10.103 7.87793C10.2313 7.74968 10.4387 7.74968 10.5669 7.87793C10.6952 8.00618 10.6952 8.21393 10.5669 8.34218L8.78717 10.1219C8.72304 10.1861 8.63904 10.2179 8.55504 10.2179Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export function AssetTick() {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M13.5441 3.06252C14.4135 2.32502 15.8372 2.32502 16.7191 3.06252L18.7098 4.76252C19.0878 5.08752 19.7933 5.35002 20.2973 5.35002H22.4392C23.7747 5.35002 24.8708 6.43752 24.8708 7.76252V9.88752C24.8708 10.375 25.1354 11.0875 25.463 11.4625L27.1765 13.4375C27.9198 14.3 27.9198 15.7125 27.1765 16.5875L25.463 18.5625C25.1354 18.9375 24.8708 19.6375 24.8708 20.1375V22.2625C24.8708 23.5875 23.7747 24.675 22.4392 24.675H20.2973C19.8059 24.675 19.0878 24.9375 18.7098 25.2625L16.7191 26.9625C15.8498 27.7 14.4261 27.7 13.5441 26.9625L11.5535 25.2625C11.1755 24.9375 10.4699 24.675 9.96596 24.675H7.78629C6.45078 24.675 5.35465 23.5875 5.35465 22.2625V20.125C5.35465 19.6375 5.09006 18.9375 4.77508 18.5625L3.07419 16.575C2.34343 15.7125 2.34343 14.3125 3.07419 13.45L4.77508 11.4625C5.09006 11.0875 5.35465 10.3875 5.35465 9.90002V7.75002C5.35465 6.42502 6.45078 5.33752 7.78629 5.33752H9.96596C10.4573 5.33752 11.1755 5.07502 11.5535 4.75002L13.5441 3.06252Z"
        fill="#31CD82"
      />
      <path
        d="M13.5946 18.9625C13.3426 18.9625 13.1032 18.8625 12.9268 18.6875L9.87779 15.6625C9.51242 15.3 9.51242 14.7 9.87779 14.3375C10.2432 13.975 10.8479 13.975 11.2133 14.3375L13.5946 16.7L19.0122 11.325C19.3776 10.9625 19.9824 10.9625 20.3477 11.325C20.7131 11.6875 20.7131 12.2875 20.3477 12.65L14.2623 18.6875C14.0859 18.8625 13.8465 18.9625 13.5946 18.9625Z"
        fill="#31CD82"
      />
    </svg>
  );
}

export function AssetCross() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M13.4188 3.06252C14.2508 2.32502 15.6134 2.32502 16.4574 3.06252L18.3626 4.76252C18.7243 5.08752 19.3995 5.35002 19.8818 5.35002H21.9317C23.2098 5.35002 24.2589 6.43752 24.2589 7.76252V9.88752C24.2589 10.375 24.5121 11.0875 24.8256 11.4625L26.4654 13.4375C27.1769 14.3 27.1769 15.7125 26.4654 16.5875L24.8256 18.5625C24.5121 18.9375 24.2589 19.6375 24.2589 20.1375V22.2625C24.2589 23.5875 23.2098 24.675 21.9317 24.675H19.8818C19.4116 24.675 18.7243 24.9375 18.3626 25.2625L16.4574 26.9625C15.6254 27.7 14.2629 27.7 13.4188 26.9625L11.5137 25.2625C11.1519 24.9375 10.4767 24.675 9.99438 24.675H7.90837C6.63024 24.675 5.5812 23.5875 5.5812 22.2625V20.125C5.5812 19.6375 5.32798 18.9375 5.02654 18.5625L3.39872 16.575C2.69937 15.7125 2.69937 14.3125 3.39872 13.45L5.02654 11.4625C5.32798 11.0875 5.5812 10.3875 5.5812 9.90002V7.75002C5.5812 6.42502 6.63024 5.33752 7.90837 5.33752H9.99438C10.4646 5.33752 11.1519 5.07502 11.5137 4.75002L13.4188 3.06252Z"
        fill="#EB5757"
      />
      <path
        d="M17.8199 12L12.0322 18"
        stroke="#EB5757"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8199 18L12.0322 12"
        stroke="#EB5757"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DueSVG() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="7" fill="#F2994A" />
      <path
        d="M10.8928 8.93362L7.46878 11.0432C7.39209 11.0902 7.29391 11.0857 7.22211 11.0302C6.8968 10.7797 6.60617 10.5257 6.41225 10.3122C6.41225 10.3122 6.2452 10.1412 6.17291 10.0327C6.05617 9.88467 6 9.69116 6 9.50315C6 9.29214 6.06155 9.09263 6.18415 8.93312C6.21199 8.90462 6.31798 8.77911 6.41812 8.67661C7.00231 8.03858 8.52726 6.98452 9.3293 6.66501C9.44604 6.61351 9.75767 6.506 9.91935 6.5C10.0752 6.5 10.2256 6.534 10.3702 6.60851C10.548 6.71101 10.6872 6.87002 10.7653 7.05853C10.8157 7.18953 10.8933 7.58255 10.8933 7.59405C10.9436 7.87457 10.9812 8.26859 10.9998 8.72861C11.0032 8.81112 10.9617 8.89112 10.8928 8.93362Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.4"
        d="M10.6638 10.0699C10.8148 9.97636 11.0048 10.0959 10.9975 10.2754C10.9794 10.6964 10.9481 11.0674 10.91 11.3434C10.9042 11.3494 10.8265 11.839 10.7371 12.0045C10.5813 12.312 10.2755 12.5 9.9468 12.5H9.91944C9.70746 12.4945 9.25661 12.3065 9.25661 12.295C9.02948 12.2005 8.73299 12.0405 8.41403 11.848C8.27043 11.7609 8.26701 11.5474 8.41061 11.4589L10.6638 10.0699Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export function MaintenanceA() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#8284E0" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0004 15.3063L8.40878 11.4927C8.70045 10.9532 9.12337 10.4792 9.59732 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#DA615C"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#DA615C"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#DA615C"
      />
    </svg>
  );
}

export function MaintenanceB() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#65AA8E" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0005 15.3063L8.40879 11.4927C8.70046 10.9532 9.12337 10.4792 9.59733 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#C2413A"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#C2413A"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#C2413A"
      />
    </svg>
  );
}

export function MaintenanceC() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#F0B78B" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0005 15.3063L8.40879 11.4927C8.70046 10.9532 9.12337 10.4792 9.59733 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#EF8985"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#EF8985"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#EF8985"
      />
    </svg>
  );
}

export function DueA() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#8284E0" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0004 15.3063L8.40878 11.4927C8.70045 10.9532 9.12337 10.4792 9.59732 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#E59C59"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#E59C59"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#E59C59"
      />
    </svg>
  );
}

export function DueB() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#65AA8E" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0004 15.3063L8.40878 11.4927C8.70045 10.9532 9.12337 10.4792 9.59732 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#D57F38"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#D57F38"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#D57F38"
      />
    </svg>
  );
}

export function DueC() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#F0B78B" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0004 15.3063L8.40878 11.4927C8.70045 10.9532 9.12337 10.4792 9.59732 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#F4B57B"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#F4B57B"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#F4B57B"
      />
    </svg>
  );
}

export function NonMedicalAssetsSVG() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M26.3008 8.9875L15.0008 15.525L3.70078 8.9875C4.20078 8.0625 4.92578 7.25 5.73828 6.8L12.4133 3.1C13.8383 2.3 16.1633 2.3 17.5883 3.1L24.2633 6.8C25.0758 7.25 25.8008 8.0625 26.3008 8.9875Z"
        fill="#1A355E"
      />
      <path
        opacity="0.6"
        d="M15.0008 15.525V27.5C14.0633 27.5 13.1258 27.3 12.4133 26.9L5.73828 23.2C4.22578 22.3625 2.98828 20.2625 2.98828 18.5375V11.4625C2.98828 10.6625 3.26328 9.78755 3.70078 8.98755L15.0008 15.525Z"
        fill="#1A355E"
      />
      <path
        d="M27.0133 11.4625V18.5375C27.0133 20.2625 25.7758 22.3625 24.2633 23.2L17.5883 26.9C16.8758 27.3 15.9383 27.5 15.0008 27.5V15.525L26.3008 8.98755C26.7383 9.78755 27.0133 10.6625 27.0133 11.4625Z"
        fill="#1A355E"
      />
    </svg>
  );
}

export function GenericAssetsSVG() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M26.3008 8.9875L15.0008 15.525L3.70078 8.9875C4.20078 8.0625 4.92578 7.25 5.73828 6.8L12.4133 3.1C13.8383 2.3 16.1633 2.3 17.5883 3.1L24.2633 6.8C25.0758 7.25 25.8008 8.0625 26.3008 8.9875Z"
        fill="#959DB2"
      />
      <path
        opacity="0.6"
        d="M15.0008 15.525V27.5C14.0633 27.5 13.1258 27.3 12.4133 26.9L5.73828 23.2C4.22578 22.3625 2.98828 20.2625 2.98828 18.5375V11.4625C2.98828 10.6625 3.26328 9.78755 3.70078 8.98755L15.0008 15.525Z"
        fill="#959DB2"
      />
      <path
        d="M27.0133 11.4625V18.5375C27.0133 20.2625 25.7758 22.3625 24.2633 23.2L17.5883 26.9C16.8758 27.3 15.9383 27.5 15.0008 27.5V15.525L26.3008 8.98755C26.7383 9.78755 27.0133 10.6625 27.0133 11.4625Z"
        fill="#959DB2"
      />
    </svg>
  );
}

export function WorkingA() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#8284E0" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0005 15.3063L8.40881 11.4927C8.70048 10.9532 9.1234 10.4792 9.59736 10.2167L13.4911 8.05837C14.3224 7.59171 15.6786 7.59171 16.5099 8.05837L20.4036 10.2167C20.8776 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#061327"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#061327"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#061327"
      />
    </svg>
  );
}

export function WorkingB() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#65AA8E" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0004 15.3063L8.40878 11.4927C8.70045 10.9532 9.12337 10.4792 9.59732 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#0E2041"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#0E2041"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#0E2041"
      />
    </svg>
  );
}

export function WorkingC() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#F0B78B" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0004 15.3063L8.40878 11.4927C8.70045 10.9532 9.12337 10.4792 9.59732 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#364E74"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#364E74"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#364E74"
      />
    </svg>
  );
}

export function DMA() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#8284E0" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0004 15.3063L8.40878 11.4927C8.70045 10.9532 9.12337 10.4792 9.59732 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#DA615C"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#DA615C"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#DA615C"
      />
    </svg>
  );
}

export function DMB() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#65AA8E" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0005 15.3063L8.40879 11.4927C8.70046 10.9532 9.12337 10.4792 9.59733 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#C2413A"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#C2413A"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#C2413A"
      />
    </svg>
  );
}

export function DMC() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#F0B78B" fillOpacity="0.15" />
      <path
        opacity="0.4"
        d="M21.5921 11.4927L15.0005 15.3063L8.40879 11.4927C8.70046 10.9532 9.12337 10.4792 9.59733 10.2167L13.4911 8.05837C14.3223 7.59171 15.6786 7.59171 16.5098 8.05837L20.4036 10.2167C20.8775 10.4792 21.3005 10.9532 21.5921 11.4927Z"
        fill="#EF8985"
      />
      <path
        opacity="0.6"
        d="M15.0005 15.3062V22.2916C14.4536 22.2916 13.9067 22.175 13.4911 21.9416L9.59733 19.7833C8.71504 19.2948 7.99316 18.0698 7.99316 17.0635V12.9364C7.99316 12.4698 8.15358 11.9593 8.40879 11.4927L15.0005 15.3062Z"
        fill="#EF8985"
      />
      <path
        d="M22.0077 12.9364V17.0635C22.0077 18.0698 21.2859 19.2948 20.4036 19.7833L16.5098 21.9416C16.0942 22.175 15.5473 22.2916 15.0005 22.2916V15.3062L21.5921 11.4927C21.8473 11.9593 22.0077 12.4698 22.0077 12.9364Z"
        fill="#EF8985"
      />
    </svg>
  );
}

export function NonMedicalAssetCard() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M26.3008 8.9875L15.0008 15.525L3.70078 8.9875C4.20078 8.0625 4.92578 7.25 5.73828 6.8L12.4133 3.1C13.8383 2.3 16.1633 2.3 17.5883 3.1L24.2633 6.8C25.0758 7.25 25.8008 8.0625 26.3008 8.9875Z"
        fill="#1A355E"
      />
      <path
        opacity="0.6"
        d="M15.0008 15.525V27.5C14.0633 27.5 13.1258 27.3 12.4133 26.9L5.73828 23.2C4.22578 22.3625 2.98828 20.2625 2.98828 18.5375V11.4625C2.98828 10.6625 3.26328 9.78749 3.70078 8.98749L15.0008 15.525Z"
        fill="#1A355E"
      />
      <path
        d="M27.0133 11.4625V18.5375C27.0133 20.2625 25.7758 22.3625 24.2633 23.2L17.5883 26.9C16.8758 27.3 15.9383 27.5 15.0008 27.5V15.525L26.3008 8.98749C26.7383 9.78749 27.0133 10.6625 27.0133 11.4625Z"
        fill="#1A355E"
      />
    </svg>
  );
}

export function Filter() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15.75C21 15.5511 20.921 15.3603 20.7803 15.2197C20.6397 15.079 20.4489 15 20.25 15H15.75C15.5511 15 15.3603 15.079 15.2197 15.2197C15.079 15.3603 15 15.5511 15 15.75C15 15.9489 15.079 16.1397 15.2197 16.2803C15.3603 16.421 15.5511 16.5 15.75 16.5H20.25C20.4489 16.5 20.6397 16.421 20.7803 16.2803C20.921 16.1397 21 15.9489 21 15.75ZM21 11.25C21 11.0511 20.921 10.8603 20.7803 10.7197C20.6397 10.579 20.4489 10.5 20.25 10.5H9.75C9.55109 10.5 9.36032 10.579 9.21967 10.7197C9.07902 10.8603 9 11.0511 9 11.25C9 11.4489 9.07902 11.6397 9.21967 11.7803C9.36032 11.921 9.55109 12 9.75 12H20.25C20.4489 12 20.6397 11.921 20.7803 11.7803C20.921 11.6397 21 11.4489 21 11.25ZM21 6.75C21 6.55109 20.921 6.36032 20.7803 6.21967C20.6397 6.07902 20.4489 6 20.25 6H3.75C3.55109 6 3.36032 6.07902 3.21967 6.21967C3.07902 6.36032 3 6.55109 3 6.75C3 6.94891 3.07902 7.13968 3.21967 7.28033C3.36032 7.42098 3.55109 7.5 3.75 7.5H20.25C20.4489 7.5 20.6397 7.42098 20.7803 7.28033C20.921 7.13968 21 6.94891 21 6.75Z"
        fill="#1C295D"
      />
    </svg>
  );
}

export function DashboardFilter() {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6.5L21 6.5"
        stroke="#1A355E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.3667L19 11.3667"
        stroke="#1A355E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16.5L17 16.5"
        stroke="#1A355E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function RefreshIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 11C21 16.52 16.52 21 11 21C5.48 21 2.11 15.44 2.11 15.44M2.11 15.44H6.63M2.11 15.44V20.44M1 11C1 5.48 5.44 1 11 1C17.67 1 21 6.56 21 6.56M21 6.56V1.56M21 6.56H16.56"
        stroke="#1A355E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CMRespond() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="7" fill="#F1963A" />
    </svg>
  );
}

export function CMRepair() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="7" fill="#A166AB" />
    </svg>
  );
}

export function CMApprove() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="7" fill="#0AB39C" />
    </svg>
  );
}

export function CMClose() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="7" fill="black" />
    </svg>
  );
}

export function Download() {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.001 13.5411C9.58701 13.5411 9.25101 13.2051 9.25101 12.7911V0.750122C9.25101 0.336122 9.58701 0.00012207 10.001 0.00012207C10.415 0.00012207 10.751 0.336122 10.751 0.750122V12.7911C10.751 13.2051 10.415 13.5411 10.001 13.5411Z"
        fill="#1A355E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.001 13.5412C9.80201 13.5412 9.61001 13.4622 9.47001 13.3202L6.55401 10.3932C6.26201 10.0992 6.26301 9.62415 6.55601 9.33215C6.85001 9.04015 7.32401 9.04015 7.61601 9.33415L10.001 11.7292L12.386 9.33415C12.678 9.04015 13.152 9.04015 13.446 9.33215C13.739 9.62415 13.74 10.0992 13.448 10.3932L10.532 13.3202C10.392 13.4622 10.2 13.5412 10.001 13.5412Z"
        fill="#1A355E"
      />
      <mask
        id="mask0_2158_10447"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="4"
        width="20"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.000213623 4.73267H20V18.4767H0.000213623V4.73267Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2158_10447)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5752 18.4767H4.43521C1.99021 18.4767 0.000213623 16.4877 0.000213623 14.0417V9.15667C0.000213623 6.71667 1.98521 4.73267 4.42621 4.73267H5.36721C5.78121 4.73267 6.11721 5.06867 6.11721 5.48267C6.11721 5.89667 5.78121 6.23267 5.36721 6.23267H4.42621C2.81221 6.23267 1.50021 7.54367 1.50021 9.15667V14.0417C1.50021 15.6607 2.81621 16.9767 4.43521 16.9767H15.5752C17.1872 16.9767 18.5002 15.6637 18.5002 14.0517V9.16767C18.5002 7.54867 17.1832 6.23267 15.5662 6.23267H14.6342C14.2202 6.23267 13.8842 5.89667 13.8842 5.48267C13.8842 5.06867 14.2202 4.73267 14.6342 4.73267H15.5662C18.0112 4.73267 20.0002 6.72267 20.0002 9.16767V14.0517C20.0002 16.4917 18.0142 18.4767 15.5752 18.4767Z"
          fill="#1A355E"
        />
      </g>
    </svg>
  );
}

// New Dashboard Icons

export function TotalAssets() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#5BB647" />
      <path
        opacity="0.4"
        d="M21.1503 23.4938L15.5003 26.7625L9.85034 23.4938C10.1003 23.0313 10.4628 22.625 10.8691 22.4L14.2066 20.55C14.9191 20.15 16.0816 20.15 16.7941 20.55L20.1316 22.4C20.5378 22.625 20.9003 23.0313 21.1503 23.4938Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.6"
        d="M15.5004 26.7625V32.75C15.0316 32.75 14.5629 32.65 14.2066 32.45L10.8691 30.6C10.1129 30.1813 9.49414 29.1313 9.49414 28.2688V24.7313C9.49414 24.3313 9.63164 23.8938 9.85039 23.4938L15.5004 26.7625Z"
        fill="#F2F2F2"
      />
      <path
        d="M21.5066 24.7313V28.2688C21.5066 29.1313 20.8879 30.1813 20.1316 30.6L16.7941 32.45C16.4379 32.65 15.9691 32.75 15.5004 32.75V26.7625L21.1504 23.4938C21.3691 23.8938 21.5066 24.3313 21.5066 24.7313Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.4"
        d="M38.0406 17.19L29.0006 22.42L19.9606 17.19C20.3606 16.45 20.9406 15.8 21.5906 15.44L26.9306 12.48C28.0706 11.84 29.9306 11.84 31.0706 12.48L36.4106 15.44C37.0606 15.8 37.6406 16.45 38.0406 17.19Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.6"
        d="M29.0006 22.42V32C28.2506 32 27.5006 31.84 26.9306 31.52L21.5906 28.56C20.3806 27.89 19.3906 26.21 19.3906 24.83V19.17C19.3906 18.53 19.6106 17.83 19.9606 17.19L29.0006 22.42Z"
        fill="#F2F2F2"
      />
      <path
        d="M38.6106 19.17V24.83C38.6106 26.21 37.6206 27.89 36.4106 28.56L31.0706 31.52C30.5006 31.84 29.7506 32 29.0006 32V22.42L38.0406 17.19C38.3906 17.83 38.6106 18.53 38.6106 19.17Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export function MedicalAssets() {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.841797" width="48" height="48" rx="24" fill="#4FC9FF" />
      <path opacity="0.4" d="M36.1426 17.9875L24.8426 24.525L13.5426 17.9875C14.0426 17.0625 14.7676 16.25 15.5801 15.8L22.2551 12.1C23.6801 11.3 26.0051 11.3 27.4301 12.1L34.1051 15.8C34.9176 16.25 35.6426 17.0625 36.1426 17.9875Z" fill="#F2F2F2" />
      <path opacity="0.6" d="M24.8426 24.5248V36.4998C23.9051 36.4998 22.9676 36.2998 22.2551 35.8998L15.5801 32.1998C14.0676 31.3623 12.8301 29.2623 12.8301 27.5373V20.4623C12.8301 19.6623 13.1051 18.7873 13.5426 17.9873L24.8426 24.5248Z" fill="#F2F2F2" />
      <path d="M36.8551 20.4623V27.5373C36.8551 29.2623 35.6176 31.3623 34.1051 32.1998L27.4301 35.8998C26.7176 36.2998 25.7801 36.4998 24.8426 36.4998V24.5248L36.1426 17.9873C36.5801 18.7873 36.8551 19.6623 36.8551 20.4623Z" fill="#F2F2F2" />
      <path d="M32.6513 23.5352L31.0275 24.4727L31.0275 26.6561L29.1331 27.7498L29.1331 29.6248L31.0275 28.5311L31.0275 30.7145L32.6513 29.777L32.6513 27.5936L34.5457 26.4998L34.5457 24.6248L32.6513 25.7186L32.6513 23.5352Z" fill="#FF5454" />
      <path d="M17.2775 23.5352L18.9013 24.4727L18.9013 26.6561L20.7957 27.7498L20.7957 29.6248L18.9013 28.5311L18.9013 30.7145L17.2775 29.777L17.2775 27.5936L15.3831 26.4998L15.3831 24.6248L17.2775 25.7186L17.2775 23.5352Z" fill="#FF5454" />
    </svg>
  );
}

export function NonMedicalAssets() {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.841797" width="48" height="48" rx="24" fill="#876AFE" />
      <path opacity="0.4" d="M36.1426 17.9875L24.8426 24.525L13.5426 17.9875C14.0426 17.0625 14.7676 16.25 15.5801 15.8L22.2551 12.1C23.6801 11.3 26.0051 11.3 27.4301 12.1L34.1051 15.8C34.9176 16.25 35.6426 17.0625 36.1426 17.9875Z" fill="#F2F2F2" />
      <path opacity="0.6" d="M24.8426 24.5248V36.4998C23.9051 36.4998 22.9676 36.2998 22.2551 35.8998L15.5801 32.1998C14.0676 31.3623 12.8301 29.2623 12.8301 27.5373V20.4623C12.8301 19.6623 13.1051 18.7873 13.5426 17.9873L24.8426 24.5248Z" fill="#F2F2F2" />
      <path d="M36.8551 20.4623V27.5373C36.8551 29.2623 35.6176 31.3623 34.1051 32.1998L27.4301 35.8998C26.7176 36.2998 25.7801 36.4998 24.8426 36.4998V24.5248L36.1426 17.9873C36.5801 18.7873 36.8551 19.6623 36.8551 20.4623Z" fill="#F2F2F2" />
    </svg>
  );
}

export function NewMedicalAssets({ fill = "#2A347B" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M19.0406 5.19L10.0006 10.42L0.960571 5.19C1.36057 4.45 1.94057 3.8 2.59057 3.44L7.93057 0.48C9.07057 -0.16 10.9306 -0.16 12.0706 0.48L17.4106 3.44C18.0606 3.8 18.6406 4.45 19.0406 5.19Z"
        fill={fill}
      />
      <path
        opacity="0.6"
        d="M10.0006 10.42V20C9.25062 20 8.50062 19.84 7.93062 19.52L2.59062 16.56C1.38062 15.89 0.390625 14.21 0.390625 12.83V7.17C0.390625 6.53 0.610625 5.83 0.960625 5.19L10.0006 10.42Z"
        fill={fill}
      />
      <path
        d="M19.6106 7.17V12.83C19.6106 14.21 18.6206 15.89 17.4106 16.56L12.0706 19.52C11.5006 19.84 10.7506 20 10.0006 20V10.42L19.0406 5.19C19.3906 5.83 19.6106 6.53 19.6106 7.17Z"
        fill={fill}
      />
      <path
        d="M16.2476 9.62823L14.9485 10.3782L14.9485 12.125L13.433 13L13.433 14.5L14.9485 13.625L14.9485 15.3717L16.2476 14.6217L16.2476 12.875L17.7631 12L17.7631 10.5L16.2476 11.375L16.2476 9.62823Z"
        fill="white"
      />
      <path
        d="M3.9486 9.62823L5.24764 10.3782L5.24764 12.125L6.76318 13L6.76318 14.5L5.24764 13.625L5.24764 15.3717L3.9486 14.6217L3.9486 12.875L2.43306 12L2.43306 10.5L3.9486 11.375L3.9486 9.62823Z"
        fill="white"
      />
    </svg>
  );
}

export function NewNonMedicalAssets({ fill = "#959DB2" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M19.0406 5.19L10.0006 10.42L0.960571 5.19C1.36057 4.45 1.94057 3.8 2.59057 3.44L7.93057 0.48C9.07057 -0.16 10.9306 -0.16 12.0706 0.48L17.4106 3.44C18.0606 3.8 18.6406 4.45 19.0406 5.19Z"
        fill={fill}
      />
      <path
        opacity="0.6"
        d="M10.0006 10.42V20C9.25062 20 8.50062 19.84 7.93062 19.52L2.59062 16.56C1.38062 15.89 0.390625 14.21 0.390625 12.83V7.17C0.390625 6.53 0.610625 5.83 0.960625 5.19L10.0006 10.42Z"
        fill={fill}
      />
      <path
        d="M19.6106 7.17V12.83C19.6106 14.21 18.6206 15.89 17.4106 16.56L12.0706 19.52C11.5006 19.84 10.7506 20 10.0006 20V10.42L19.0406 5.19C19.3906 5.83 19.6106 6.53 19.6106 7.17Z"
        fill={fill}
      />
    </svg>
  );
}

export function NonMedicalIcon() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.600098" width="48" height="48" rx="24" fill="#2A347B" />
      <path
        opacity="0.4"
        d="M35.9009 17.9875L24.6009 24.525L13.3009 17.9875C13.8009 17.0625 14.5259 16.25 15.3384 15.8L22.0134 12.1C23.4384 11.3 25.7634 11.3 27.1884 12.1L33.8634 15.8C34.6759 16.25 35.4009 17.0625 35.9009 17.9875Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.6"
        d="M24.6009 24.525V36.5C23.6634 36.5 22.7259 36.3001 22.0134 35.9001L15.3384 32.2C13.8259 31.3625 12.5884 29.2625 12.5884 27.5375V20.4625C12.5884 19.6625 12.8634 18.7875 13.3009 17.9875L24.6009 24.525Z"
        fill="#F2F2F2"
      />
      <path
        d="M36.6134 20.4625V27.5375C36.6134 29.2625 35.3759 31.3625 33.8634 32.2L27.1884 35.9001C26.4759 36.3001 25.5384 36.5 24.6009 36.5V24.525L35.9009 17.9875C36.3384 18.7875 36.6134 19.6625 36.6134 20.4625Z"
        fill="#F2F2F2"
      />
    </svg>
  );
}

export function MedicalIcon() {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.841797" width="48" height="48" rx="24" fill="#5BB647" />
      <path
        opacity="0.4"
        d="M36.1426 17.9875L24.8426 24.525L13.5426 17.9875C14.0426 17.0625 14.7676 16.25 15.5801 15.8L22.2551 12.1C23.6801 11.3 26.0051 11.3 27.4301 12.1L34.1051 15.8C34.9176 16.25 35.6426 17.0625 36.1426 17.9875Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.6"
        d="M24.8426 24.525V36.5C23.9051 36.5 22.9676 36.3001 22.2551 35.9001L15.5801 32.2C14.0676 31.3625 12.8301 29.2625 12.8301 27.5375V20.4625C12.8301 19.6625 13.1051 18.7875 13.5426 17.9875L24.8426 24.525Z"
        fill="#F2F2F2"
      />
      <path
        d="M36.8551 20.4625V27.5375C36.8551 29.2625 35.6176 31.3625 34.1051 32.2L27.4301 35.9001C26.7176 36.3001 25.7801 36.5 24.8426 36.5V24.525L36.1426 17.9875C36.5801 18.7875 36.8551 19.6625 36.8551 20.4625Z"
        fill="#F2F2F2"
      />
      <path
        d="M32.6513 23.5353L31.0275 24.4728L31.0275 26.6562L29.1331 27.75L29.1331 29.625L31.0275 28.5312L31.0275 30.7147L32.6513 29.7772L32.6513 27.5937L34.5457 26.5L34.5457 24.625L32.6513 25.7187L32.6513 23.5353Z"
        fill="#FF5454"
      />
      <path
        d="M17.2775 23.5353L18.9013 24.4728L18.9013 26.6562L20.7957 27.75L20.7957 29.625L18.9013 28.5312L18.9013 30.7147L17.2775 29.7772L17.2775 27.5937L15.3831 26.5L15.3831 24.625L17.2775 25.7187L17.2775 23.5353Z"
        fill="#FF5454"
      />
    </svg>
  );
}
