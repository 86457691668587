import { inventoryIcon, listIcon, scheduledIcon } from "assets/icons/svgIcons";

export default [
  {
    id: "inventory",
    title: "Inventory",
    icon: inventoryIcon,
    type: "collapse",
    pageTitle: "Inventory List",
    isActive: true,
    permissions: [
      "super_admin",
      "site_admin",
      "organization_admin",
      "staff",
      "engineer",
      "hmc_admin",
      "hmc_supervisor",
      "hmc_technician",
      "qa",
      "helpdesk",
      "call_center",
    ],
    children: [
      {
        id: "inventoryList",
        title: "List",
        icon: listIcon,
        pageTitle: "Inventories List",
        navLink: "/inventories",
        type: "item",
        permissions: [
          "super_admin",
          "site_admin",
          "organization_admin",
          "staff",
          "engineer",
          "hmc_admin",
          "hmc_supervisor",
          "hmc_technician",
          "qa",
          "helpdesk",
          "call_center",
        ],
      },
      {
        id: "inventoryPMCalender",
        title: "PM Calender",
        icon: scheduledIcon,
        pageTitle: "Preventive Maintenance Calender",
        navLink: "/inventories/pm-calender",
        type: "item",
        isWorkOrder: true,
        permissions: [
          "super_admin",
          "site_admin",
          "organization_admin",
          "staff",
          "engineer",
          "hmc_admin",
          "hmc_supervisor",
          "hmc_technician",
          "qa",
          "helpdesk",
          "call_center",
        ],
      },
    ],
  },
  {
    id: "inventory-upcoming",
    title: "Upcoming Out Of Warranty Assets",
    icon: inventoryIcon,
    type: "item",
    navLink: "/inventories-upcoming",
    pageTitle: "Upcoming Out Of Warranty Assets",
    permissions: ["super_admin"],
    isDisabledFromSideMenu: true,
  },
];
