import React from "react";
import { observer } from "mobx-react-lite";
import { FormattedMessage } from "react-intl";

const FieldValidation = observer(({ validations, isTransparent }) =>
  validations?.length ? (
    <ul className={`fieldValidation ${isTransparent && "isTransparent"}`}>
      {validations?.map((item, index) => (
        <li key={index}>
          <FormattedMessage id={item?.message} defaultMessage={item?.message} />
        </li>
      ))}
    </ul>
  ) : (
    <div />
  )
);

export { FieldValidation };
